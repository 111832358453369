import parse from 'html-react-parser';
const StreamerQuestionComponent = (p:{questionNumber:number, questionText:string}) => {

    return (<div className="question_frame">
        <div className="question_number">{p.questionNumber}</div>
        <div className="question_text">
            {parse(p.questionText)}
        </div>
    </div>)
}

export default StreamerQuestionComponent;