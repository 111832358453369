import {useDispatch, useSelector, useStore} from "react-redux";
import React, {useEffect, useState} from "react";
import StreamerAppState from "./store/storeBaseTypes";
import {setBackendInfo, setGameId, setTwitchCredentialsForStreamer} from "./store/actionCreators";
import {BackendConfig, GetBackendURL} from "./configs/BackendConfig";
import StreamerAppViewState from "./store/StreamerAppViewState";
import StreamerLogin from "./StreamerLogin";
import StreamerGame from "./StreamerGame";
import GlobalConst from "./GlobalConst";
import TwitchUserInfo from "./data/TwitchUserInfo";
import StreamerLoggedIn from "./StreamerLoggedIn";
import {StreamerStartGameResponse} from "./data/StreamerStartGameResponse";
import StreamerEndGame from "./StreamerEndGame";

const decodeTwitchTokenValue = (twitchResponse:string) => {
  let cleanObject = twitchResponse.substring(1, twitchResponse.length);
  let properties = cleanObject.split("&");
  let tokenValue = properties[0].split("=")[1];
  return tokenValue;
}

const App = () => {
  const dispatch = useDispatch()
  const store = useStore();
  const backendInfoSelector = useSelector( (x: StreamerAppState) => x.backendInfo);
  const twitchInfoSelector = useSelector( (x:StreamerAppState) => x.twitchInfo);

  const [appViewState, setAppViewState] = useState(StreamerAppViewState.StartUp);
  let appState = store.getState() as StreamerAppState;

  const requestUserInfo = async (url:URL, authToken:string) => {
    //https://dev.twitch.tv/docs/api/reference/#get-users
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'Client-Id': `${GlobalConst.TWITCH_CLIENT_ID}`},
    })
    console.log("Twitch response " + JSON.stringify(response));
    const userArray:TwitchUserInfo[] = JSON.parse(await response.text()).data;
    console.log("StreamerTwitch UserID " + userArray[0].id); //Correct UserId as integer is being received
    console.log("StreamerTwitch Display name " + userArray[0].display_name);
    dispatch(setTwitchCredentialsForStreamer(userArray[0].id, userArray[0].display_name, authToken));
  }

  //Processing potential return from Twitch
  console.log("document.location.hash " + document.location.hash);
  if (appState.twitchInfo === null && document.location.hash.length > 0 ) {
    let tokenValue = decodeTwitchTokenValue(document.location.hash);
    console.log("tokenValue " + tokenValue);
    //Send UserId request
    const myUrlWithParams = new URL("https://api.twitch.tv/helix/users");
    requestUserInfo(myUrlWithParams, tokenValue);
  }

  useEffect(() => {
    const onStateUpdate = async () => {
      let appState = store.getState() as StreamerAppState;
      console.log("OnStateUpdated " + appState.appViewState + " " + typeof appState.appViewState);
      setAppViewState(appState.appViewState);
    };

    const onPageLoad = () => {
      store.subscribe(onStateUpdate)
      console.log("Client: setting backend info");

      let appState = store.getState() as StreamerAppState;
      console.log(appState);
      if ( appState.appViewState === StreamerAppViewState.StartUp) {
        dispatch(setBackendInfo(GetBackendURL(BackendConfig.RemoteSecure)))
      }
    }

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      console.log("Document is in ready state ")
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }

  }, [dispatch, store]);

  switch ( appViewState ) {
    case StreamerAppViewState.BackendSet:
      return (<StreamerLogin currentState={appViewState}/>)
    case StreamerAppViewState.GameInProgress:
      return (<StreamerGame/>);
    case StreamerAppViewState.GameEnded:
      return (<StreamerEndGame Players={appState.latestRating}/>);
    case StreamerAppViewState.TwitchTokenReceived:
    case StreamerAppViewState.BackIntoLobby:
      return <StreamerLoggedIn currentState={appViewState}/>
    case StreamerAppViewState.AttemptToStartGame:
      const twitchUserID = twitchInfoSelector?.twitchId as string;
      const twitchToken = twitchInfoSelector?.accessToken as string;

      let formData = new FormData();
      formData.append("twitchUserId", twitchUserID);
      formData.append("twitchToken", twitchToken);

      const backendURL = backendInfoSelector?.toFinalURL();
      console.log("Trying to start game")
      fetch(backendURL + "/api/start_game", {
        method: 'POST',
        body: formData,
      }).then( x=> x.text().then( x => {
        let playerStatusResponse: StreamerStartGameResponse = JSON.parse(x);
        dispatch(setGameId(playerStatusResponse.GameId));
      }));
      //TODO: Here we should return loader until game creation is complete
      return <div/>
    default:
      return (<div>{appViewState.toString()}</div>);
  }
}

export default App;