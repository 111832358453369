import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./main.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import {DispatchType, StreamerAppState} from "./store/storeBaseTypes";
import reducerStreamer from "./store/reducerStreamer";
import {createStore, applyMiddleware, Store} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const store:Store<StreamerAppState> & {
    dispatch: DispatchType
} = createStore(reducerStreamer, applyMiddleware(thunk))
root.render(
    //<React.StrictMode>
    //With Strict mode "on" Component will be rendered twice
    // https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
    <Provider store={store}>
        <App></App>
    </Provider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
