import {ViewerInGameRecord} from "./data/ViewerInGameRecord";
import {setStreamerAppViewState} from "./store/actionCreators";
import StreamerAppViewState from "./store/StreamerAppViewState";
import {useDispatch} from "react-redux";
import StreamerEndGameLeaderboardTopItemComponent from "./widgets/StreamerEndGameLeaderboardTopItemComponent";
import StreamerEndGameLeaderboardItemComponent from "./widgets/StreamerEndGameLeaderboardItemComponent";


const StreamerEndGame= (p:{Players:ViewerInGameRecord[]}) =>
{
    const dispatch = useDispatch();
    let sortedArray = p.Players.sort((n1, n2) => n1.Points - n2.Points);
    let topLeaderboardItems:JSX.Element[] = new Array<JSX.Element>();
    for ( let i = 0; i < sortedArray.length && i < 3 ; ++i) {
        let x = sortedArray[i];
        topLeaderboardItems.push(<StreamerEndGameLeaderboardTopItemComponent place={i + 1} nickname={x.NickName} points={x.Points}/>)
    }

    for ( let i = 3; i < sortedArray.length ; ++i) {
        let x = sortedArray[i];
        topLeaderboardItems.push(<StreamerEndGameLeaderboardItemComponent place={i + 1} nickname={x.NickName} points={x.Points}/>)
    }

    return (
        <div>
            <div className="top_number">
                <div className="top_number_text">Конец игры</div>
            </div>

            <div className="main_frame">
                <div className="winners_board">
                </div>

                <div className="leaderboard_winners_frame">
                    {topLeaderboardItems}
                </div>

                <div className="button_end_game"
                     onClick={() => dispatch(setStreamerAppViewState(StreamerAppViewState.BackIntoLobby))}>
                    <div className="button_end_game_text">
                        Закончить игру
                    </div>
                </div>
            </div>
        </div>)
}

export default StreamerEndGame;