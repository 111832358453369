export enum BackendConfig {
    Local,
    Remote,
    RemoteSecure
};

export class BackendInfo {
    public url:string = "";
    public port: number = 0;

    constructor(url:string, port:number) {
        this.url = url;
        this.port = port;
    }

    toFinalURL = ():string => this.url + ":" + this.port.toString();
}

export const GetBackendURL = (backend:BackendConfig):BackendInfo => {
    if ( backend === BackendConfig.Remote) {
        return new BackendInfo ( "https://actionstream.xyz",  2244 );
    }
    else if ( backend === BackendConfig.RemoteSecure ) {
        return new BackendInfo ( "https://actionstream.xyz",  2243 );
    }
    return new BackendInfo("http://localhost", 5002);
};

