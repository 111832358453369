import StreamerQuestionComponent from "./widgets/StreamerQuestionComponent";
import StreamerLeaderboardItemComponent from "./widgets/StreamerLeaderboardItemComponent";
import StreamerAppState from "./store/storeBaseTypes";
import useInterval from "use-interval";
import {useDispatch, useStore} from "react-redux";
import {StreamerGameInfoResponse} from "./data/StreamerGameInfoResponse";
import {useEffect, useState} from "react";
import {ViewerInGameRecord} from "./data/ViewerInGameRecord";
import {setLatestRatings, setStreamerAppViewState} from "./store/actionCreators";
import StreamerAppViewState from "./store/StreamerAppViewState";

const StreamerGame = () => {
    const store = useStore();
    const dispatch = useDispatch();

    const [questionId, setQuestionId] = useState(-1);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [desiredAnswer, setDesiredAnswer] = useState(-1);
    const [players, setPlayers] = useState(new Array<ViewerInGameRecord>());

    useEffect(() => {
        updateInfo().then(r => console.log(r));
    });


    const nextQuestion = async () => {

        let appState = store.getState() as StreamerAppState;
        const url = appState.backendInfo?.toFinalURL() + "/api/next_question";
        const twitchToken = appState.twitchInfo?.accessToken as string;
        const gameId = appState.gameId as string;

        let formData = new FormData();
        formData.append("twitchToken", twitchToken);
        formData.append("gameId", gameId);

        let result = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        let nextQuestionResponse = await result.text();
        console.log("Next question triggered " + nextQuestionResponse);
        if ( nextQuestionResponse !== "Question changed") {
            const rating:ViewerInGameRecord[] = JSON.parse(nextQuestionResponse);
            dispatch(setLatestRatings(rating));
            dispatch(setStreamerAppViewState(StreamerAppViewState.GameEnded));
            return;
        }
        await updateInfo();
    }

    const updateInfo = async () => {
        console.log("Streamer: update game info");
        let appState = store.getState() as StreamerAppState;
        if (appState.backendInfo != null) {
            const url = appState.backendInfo.toFinalURL() + "/api/get_game_info";
            const twitchUserId = appState.twitchInfo?.twitchId as string;
            const twitchToken = appState.twitchInfo?.accessToken as string;
            const gameId = appState.gameId as string;

            let formData = new FormData();
            formData.append("twitchUserId", twitchUserId);
            formData.append("twitchToken", twitchToken);
            formData.append("gameId", gameId);

            let result = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            let gameInfoResponse = await result.text();

            console.log("State updated " + JSON.stringify(gameInfoResponse));

            let gameInfo:StreamerGameInfoResponse = JSON.parse(gameInfoResponse);
            setCurrentQuestion(gameInfo.QuestionText);
            setDesiredAnswer(gameInfo.AnswerNumber);
            setQuestionId(gameInfo.CurrentGameQuestionId);
            setPlayers(gameInfo.Players);
        }
    }

    useInterval(updateInfo, 2000);

    return (<div>
            <div className="top_number">
                <div className="top_number_text">Загаданное число: {desiredAnswer}</div>
            </div>

            <div className="main_frame">
                <StreamerQuestionComponent questionNumber={questionId} questionText={currentQuestion}/>

                <div className="leaderboard_frame">
                    {players.map((x, i) => <StreamerLeaderboardItemComponent
                        place={i + 1} nickname={x.NickName} points={x.Points}/>)}
                </div>

                <div className="button_next_stage" onClick={() => nextQuestion()}>
                        <div className="button_next_stage_text">
                            Закончить вопрос
                        </div>
                </div>
            </div>
        </div>)
}


export default StreamerGame;