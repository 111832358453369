import {useDispatch} from "react-redux";
import StreamerAppViewState from "./store/StreamerAppViewState";
import {setStreamerAppViewState} from "./store/actionCreators";
import GlobalConst from "./GlobalConst";

const StreamerLogin = (p:{currentState: StreamerAppViewState}) => {
    const dispatch = useDispatch()
    const scope = "user:read:email";
    const redirect_url = "https://guessandbetstreamer.actionstream.xyz/";
    const response_type = "token";

    const myUrlWithParams = new URL("https://id.twitch.tv/oauth2/authorize");

    myUrlWithParams.searchParams.append("client_id", GlobalConst.TWITCH_CLIENT_ID);
    myUrlWithParams.searchParams.append("force_verify", "true");
    myUrlWithParams.searchParams.append("response_type", response_type);
    myUrlWithParams.searchParams.append("scope", scope);
    myUrlWithParams.searchParams.append("redirect_uri", redirect_url);

    const authURL = myUrlWithParams.href;
    console.log("authURL " + authURL)

    return (<div>
        <div className="top_number">
            <div className="top_number_text">Скройте эту область!</div>
        </div>

        <div className="main_frame">
            <a href={authURL}>
                <div className="button_play"
                     onClick={() => dispatch(setStreamerAppViewState(StreamerAppViewState.AttemptingToAuthorize))}>
                    <div className="button_text">
                        Connect to twitch
                    </div>
                </div>
            </a>
        </div>
    </div>);
}

export default StreamerLogin;