export class TwitchCredentialsStreamer {
    public twitchId:string = "";
    public twitchName:string = "";
    public accessToken: string = "";

    constructor(twitchId:string, twitchName:string, accessToken:string) {
        this.twitchId = twitchId;
        this.twitchName = twitchName;
        this.accessToken = accessToken;
    }
}