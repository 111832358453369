import {AppAction, StreamerAppState} from "./storeBaseTypes";
import * as actionTypes from "./actionTypes";
import StreamerAppViewState from "./StreamerAppViewState";
import {BackendInfo} from "../configs/BackendConfig";
import {TwitchCredentialsStreamer} from "../configs/TwitchCredentialsStreamer";
import {ViewerInGameRecord} from "../data/ViewerInGameRecord";

const initialState: StreamerAppState = {
    appViewState: StreamerAppViewState.StartUp,
    backendInfo: null,
    twitchInfo: null,
    gameId: null,
    latestRating: [],
    asyncOperationInProgress:false
}

const reducerStreamer = (
    state: StreamerAppState = initialState,
    action: AppAction
): StreamerAppState => {
    switch (action.type) {
        case actionTypes.SET_APP_VIEW_STATE:
            let argsMap = action.args as Map<string, string>;
            let enumValueObj = argsMap.get("appViewState");
            if (enumValueObj !== undefined) {
                let newStateValue = parseInt(enumValueObj) as StreamerAppViewState;
                return {
                    ...state,
                    appViewState: newStateValue
                }
            }
            else
            {
                throw new Error("Incorrect serialization of SET_APP_VIEW_STATE");
            }
        case actionTypes.SET_BACKEND_INFO:
            console.log("Backend set in reducer");
            let argsDict = action.args as Map<string, string>;
            let backendInfo = new BackendInfo(argsDict.get("backendURL") as string,
                Number(argsDict.get("backendPort")));
            return {
                ...state,
                appViewState: StreamerAppViewState.BackendSet,
                backendInfo: backendInfo
            }
        case actionTypes.SET_TWITCH_CREDENTIALS:
            console.log("Twitch credentials set for streamer")
            let argsDict2 = action.args as Map<string, string>;
            let twitchCredentials = new TwitchCredentialsStreamer(
                argsDict2.get("twitchId") as string,
                argsDict2.get("twitchName") as string,
                argsDict2.get("accessToken") as string);
            return {
                ...state,
                appViewState: StreamerAppViewState.TwitchTokenReceived,
                twitchInfo: twitchCredentials
            }
        case actionTypes.SET_GAME_ID:
            let argsDict3 = action.args as Map<string, string>;
            console.log("Set game id " + argsDict3.get("gameId") as string);
            return {
                ...state,
                appViewState: StreamerAppViewState.GameInProgress,
                gameId: argsDict3.get("gameId") as string
            }
        case actionTypes.SET_LATEST_RATINGS:
            return {
                ...state,
                latestRating: action.args as ViewerInGameRecord[]
            }
        case actionTypes.SET_GLOBAL_ASYNC_OP_IN_PROGRESS:
            let argsDict4 = action.args as Map<string, string>;
            return {
                ...state,
                asyncOperationInProgress: argsDict4.get("asyncOpInProgress") === "true"
            }
    }

    return state;
}

export default reducerStreamer;