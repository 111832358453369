import {AppAction} from "./storeBaseTypes";
import * as actionTypes from "./actionTypes";
import {BackendInfo} from "../configs/BackendConfig";
import StreamerAppViewState from "./StreamerAppViewState";
import {ViewerInGameRecord} from "../data/ViewerInGameRecord";


export function setTwitchCredentialsForStreamer(twitchId:string, twitchName:string, accessToken:string):AppAction {

    const args = new Map<string, string>();
    args.set("twitchId", twitchId);
    args.set("twitchName", twitchName);
    args.set("accessToken", accessToken);

    const action: AppAction = {
        type: actionTypes.SET_TWITCH_CREDENTIALS,
        args: args
    }
    return action;
}

export function setBackendInfo(backendInfo:BackendInfo):AppAction {

    const args = new Map<string, string>();
    args.set("backendURL", backendInfo.url);
    args.set("backendPort", backendInfo.port.toString());

    const action: AppAction = {
        type: actionTypes.SET_BACKEND_INFO,
        args: args
    }
    return action;
}

export function setAsyncOpInProgress(asyncOpInProgress:boolean):AppAction {

    const args = new Map<string, string>();
    args.set("asyncOpInProgress", asyncOpInProgress.toString());

    const action: AppAction = {
        type: actionTypes.SET_GLOBAL_ASYNC_OP_IN_PROGRESS,
        args: args
    }
    return action;
}

export function setGameId(gameId:string):AppAction {

    const args = new Map<string, string>();
    args.set("gameId", gameId);

    const action: AppAction = {
        type: actionTypes.SET_GAME_ID,
        args: args
    }
    return action;
}

export function setStreamerAppViewState(appViewState:StreamerAppViewState):AppAction {
    const args = new Map<string, string>();
    args.set("appViewState", appViewState.toString());

    const action: AppAction = {
        type: actionTypes.SET_APP_VIEW_STATE,
        args: args
    }
    return action;
}

export function setLatestRatings(viewers:ViewerInGameRecord[]):AppAction {

    const action: AppAction = {
        type: actionTypes.SET_LATEST_RATINGS,
        args: viewers
    }
    return action;
}