import {useDispatch} from "react-redux";
import StreamerAppViewState from "./store/StreamerAppViewState";
import {setStreamerAppViewState} from "./store/actionCreators";

const StreamerLoggedIn = (p:{currentState: StreamerAppViewState}) => {
    const dispatch = useDispatch()

    return (<div>
        <div className="top_number">
            <div className="top_number_text">Скройте эту область!</div>
        </div>

        <div className="main_frame">
            <div className="button_play"
                 onClick={() => dispatch(setStreamerAppViewState(StreamerAppViewState.AttemptToStartGame))}>
                <div className="button_text">
                    Играть!
                </div>
            </div>
        </div>
    </div>);
}

export default StreamerLoggedIn;