enum StreamerAppViewState {
    StartUp,
    BackendSet,
    AttemptingToAuthorize= 2,
    TwitchTokenReceived = 3,
    AttemptToStartGame = 4,
    FailToStarGame = 5,
    GameInProgress = 6,
    GameEnded,
    BackIntoLobby = 8
}

export default StreamerAppViewState;