const StreamerEndGameLeaderboardTopItemComponent = (p:{place:number, nickname:string, points:number}) => {

    let classNameValue = "";
    switch (p.place) {
        case 1:
            classNameValue = "leaderboard_first_place";
            break;
        case 2:
            classNameValue = "leaderboard_second_place";
            break;
        case 3:
            classNameValue = "leaderboard_third_place";
            break;
        default:
            throw new DOMException("StreamerEndGameLeaderboardTopItemComponent can only be used for the first 3 places")
    }

    return (<div className={classNameValue}>
        <div className="leaderboard_winner_place">{p.place}</div>
        <div className="leaderboard_winner_nickname">{p.nickname}</div>
        <div className="leaderboard_winner_points">{p.points}</div>
    </div>)
}

export default StreamerEndGameLeaderboardTopItemComponent;